import React from "react";
import { useNavigate } from "react-router-dom";

import "../styles/menu.scss";

const MenuButtons = () => {
    const navigate = useNavigate();

    return (
        <section id="menu">
            <div className="menu-button to-home" onClick={() => navigate("/")}>
                <img src="/img/home-text.png" />
            </div>
            <div className="menu-button to-result" onClick={() => navigate("/result")}>
                <img src="/img/result-text.png" />
            </div>
            <div className="menu-button to-statistic" onClick={() => navigate("/statistic")}>
                <img src="/img/statistic-text.png" />
            </div>
        </section>
    );
};

export default MenuButtons;