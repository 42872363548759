import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/home";
import Result from "./pages/result";
import Statistic from "./pages/statistic";
import HonorBoard from "./pages/honorboard";


const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/result" element={<Result />} />
                <Route path="/result/:Year" element={<Result />} />
                <Route path="/result/:Year/:ID" element={<Result />} />
                <Route path="/statistic" element={<Statistic />} />
                <Route path="/honor-board" element={<HonorBoard />} />
                <Route path="/honor-board/:Year" element={<HonorBoard />} />
                <Route path="/honor-board/:Year/:Alias" element={<HonorBoard />} />

                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Router>
    );
};

export default App;
