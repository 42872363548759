import React from "react";

import "../styles/footer.scss";

const Footer = () => {
    return (
        <section id="footer">
            <p>Trong trường hợp cần hỗ trợ đặc biệt, phụ huynh vui lòng liên hệ qua hotline: 0969.893.544</p>
            <p>© <a href="https://hoctoantienganh.com">Mathlish</a> 2024 - All Rights Reserved</p>
        </section>
    );
};

export default Footer;