import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";

import { InputBase, IconButton, Box, Paper } from "@mui/material";
import { Search } from "@mui/icons-material";

import Menu from "../components/menu";
import HeadBanner from "../components/headbanner";
import Footer from "../components/footer";
import Banner from "../components/banner";

import "../styles/menu.scss";
import "../styles/home-intro.scss";
import "../styles/result.scss";
import "../styles/footer.scss";


const Page = (): JSX.Element => {
    const navigate = useNavigate();
    const { Year, ID } = useParams();
    
    const [studentId, setStudentId] = useState(ID || "");
    const [searchResult, setSearchResult] = useState("");

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };

    const handleScroll = (targetId: string) => {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleSearch = async () => {
        try {
            let resultHtml = "";

            if (!studentId) {
                resultHtml = `<div id="search-result"><p>Vui lòng nhập số báo danh để tìm kiếm!</p></div>`;
                setSearchResult(resultHtml);
                return;
            }

            if (!Number.isInteger(Number(studentId)) || /[^0-9]/.test(studentId)) {
                resultHtml = `<div id="search-result"><p>Số báo danh không hợp lệ. Xin vui lòng thử lại!</p></div>`;
                setSearchResult(resultHtml);
                return;
            }

            const response = await axios.get(
                `https://api.mathlishtalent.vn/v1/result/2024/firstround?id=${studentId}`
            );

            const res = response.data;

            if (!res.id) {
                resultHtml = `
                    <div id="search-result">
                        <p>Không tìm thấy kết quả cho số báo danh <strong>${studentId}</strong>.</p>
                        <p>Vui lòng kiểm tra lại số báo danh hoặc liên hệ ban tổ chức để được hỗ trợ.</p>
                    </div>
                `;
                setSearchResult(resultHtml);
                return;
            }

            resultHtml += `
            <div id="search-result">
                <p><strong>${res.name}</strong></p>
                <p>Số báo danh: ${res.id}</p>
                <p>Khối lớp: ${res.grade}</p>

                <span class="round-divider"></span>
                <p>Kết quả vòng 1: <strong>${res.result}</strong></p>
            `;

            if (res.finalScore) {
                resultHtml += `<p>Điểm số: <strong>${res.finalScore} / 100</strong></p>`;
            }

            switch (res.result) {
                case "Không tham gia":
                    resultHtml += `
                        <p>BTC rất tiếc vì lí do nào đó em chưa thể tham gia dự thi Mathlish Talent 2024.</p>
                        <p>Chúng tôi hy vọng em có thể trải nghiệm và đồng hành cùng Mathlish Talent ở những mùa tiếp theo.</p>
                        <p>Xin trân trọng cảm ơn em cùng gia đình!</p>
                    `;
                    break;
                case "Không đạt":
                    resultHtml += `
                        <p>BTC rất tiếc vì kết quả thi vòng 1 Mathlish Talent 2024 của em không nằm trong top 25 để được vào vòng 2.</p>
                        <p>Chúng tôi hy vọng em sẽ tiếp tục giữ lửa say mê học tập và tham gia Mathlish Talent ở những năm học tiếp theo.</p>
                        <p>Xin trân trọng cảm ơn em cùng gia đình!</p>
                    `;
                    break;
                case "Đạt":
                    if (res.gradeRanking) {
                        resultHtml += `<p>Xếp hạng theo khối thi: <strong>TOP ${res.gradeRanking}</strong></p>`;
                    }

                    if (res.pointString) {
                        resultHtml += `
                            <p>Chuỗi câu trả lời đúng liên tiếp dài nhất: <strong>${getMaxCorrectSeries(res.pointString)}</strong></p>
                            <p>Dữ liệu chi tiết bài thi của thí sinh: <strong>${drawAnswerTable(res.pointString)}</strong></p>
                        `;
                    }

                    resultHtml += `
                        <p>Xin chúc mừng em đã đạt kết quả xuất sắc thuộc top 25 thí sinh trong khối và được tham gia vòng 2 Mathlish Talent 2024.</p>
                        <p>Chúng tôi rất mong được chứng kiến sự thể hiện xuất sắc của em ở vòng 2 Mathlish Talent 2024 tới đây.</p>
                        <p>Xin chúc mừng em và gia đình!</p>
                    `;
                    break;
                default:
            }


            if (res.round2Result) {
                switch (res.round2Result) {
                    case "Không tham gia":
                        resultHtml += `
                            <span class="round-divider"></span>
                            <p>Kết quả vòng 2: <strong>${res.round2Result}</strong></p>
                        `;
                        break;

                    case "Không đạt giải":
                        resultHtml += `
                            <span class="round-divider"></span>
                            <p>Kết quả vòng 2: <strong>Giải Khuyến Khích</strong></p>
                        `;
                        break;

                    case "Giải vàng":
                        resultHtml += `
                            <span class="round-divider"></span>
                            <p>Kết quả vòng 2: <strong>Huy Chương Vàng</strong></p>
                        `;
                        break;

                    case "Giải bạc":
                        resultHtml += `
                            <span class="round-divider"></span>
                            <p>Kết quả vòng 2: <strong>Huy Chương Bạc</strong></p>
                        `;
                        break;

                    case "Giải đồng":
                        resultHtml += `
                            <span class="round-divider"></span>
                            <p>Kết quả vòng 2: <strong>Huy Chương Đồng</strong></p>
                        `;
                        break;
                }

                if (res.round2FinalScore) {
                    resultHtml += `<p>Điểm số vòng 2: <strong>${res.round2FinalScore} / 100</strong></p>`;
                }

                switch (res.round2Result) {
                    case "Không tham gia":
                        resultHtml += `
                            <p>BTC rất tiếc vì lí do nào đó em chưa thể tham gia dự thi Mathlish Talent 2024 vòng 2.</p>
                            <p>Chúng tôi hy vọng em có thể trải nghiệm và đồng hành cùng Mathlish Talent ở những mùa tiếp theo.</p>
                            <p>Xin trân trọng cảm ơn em cùng gia đình!</p>
                        `;
                        break;

                    case "Không đạt giải":
                        if (res.round2PointString) {
                            resultHtml += `
                                <p>Chuỗi câu trả lời đúng liên tiếp dài nhất: <strong>${getMaxCorrectSeries(res.round2PointString)}</strong></p>
                                <p>Dữ liệu chi tiết bài thi của thí sinh: <strong>${drawAnswerTable(res.round2PointString)}</strong></p>
                            `;
                        }

                        resultHtml += `
                            <p>BTC xin chúc mừng em đã đạt <strong>Giải Khuyến Khích</strong> cuộc thi Mathlish Talent 2024.</p>
                            <p>Chúng tôi hy vọng em sẽ tiếp tục giữ lửa say mê học tập và đạt những thành tích cao hơn nữa tại cuộc thi Mathlish Talent ở những năm học tiếp theo.</p>
                            <p>Xin trân trọng cảm ơn em cùng gia đình!</p>
                        `;
                        break;

                    case "Giải vàng":
                        if (res.round2Time) {
                            resultHtml += `<p>Thời gian làm bài: <strong>${res.round2Time}</strong></p>`;
                        }

                        if (res.round2PointString) {
                            resultHtml += `
                                <p>Chuỗi câu trả lời đúng liên tiếp dài nhất: <strong>${getMaxCorrectSeries(res.round2PointString)}</strong></p>
                                <p>Dữ liệu chi tiết bài thi của thí sinh: <strong>${drawAnswerTable(res.round2PointString)}</strong></p>
                            `;
                        }

                        resultHtml += `
                            <p>BTC xin chúc mừng em đã đạt <strong>Huy Chương Vàng</strong> cuộc thi Mathlish Talent 2024.</p>
                            <p>Chúng tôi hy vọng em sẽ tiếp tục giữ lửa say mê học tập và giữ vững thành tích tuyệt vời này tại cuộc thi Mathlish Talent ở những năm học tiếp theo.</p>
                            <p>Xin trân trọng cảm ơn em cùng gia đình!</p>
                        `;
                        break;

                    case "Giải bạc":
                        if (res.round2Time) {
                            resultHtml += `<p>Thời gian làm bài: <strong>${res.round2Time}</strong></p>`;
                        }

                        if (res.round2PointString) {
                            resultHtml += `
                                <p>Chuỗi câu trả lời đúng liên tiếp dài nhất: <strong>${getMaxCorrectSeries(res.round2PointString)}</strong></p>
                                <p>Dữ liệu chi tiết bài thi của thí sinh: <strong>${drawAnswerTable(res.round2PointString)}</strong></p>
                            `;
                        }

                        resultHtml += `
                            <p>BTC xin chúc mừng em đã đạt <strong>Huy Chương Bạc</strong> cuộc thi Mathlish Talent 2024.</p>
                            <p>Chúng tôi hy vọng em sẽ tiếp tục giữ lửa say mê học tập và đạt những thành tích cao hơn nữa tại cuộc thi Mathlish Talent ở những năm học tiếp theo.</p>
                            <p>Xin trân trọng cảm ơn em cùng gia đình!</p>
                        `;
                        break;

                    case "Giải đồng":
                        if (res.round2Time) {
                            resultHtml += `<p>Thời gian làm bài: <strong>${res.round2Time}</strong></p>`;
                        }

                        if (res.round2PointString) {
                            resultHtml += `
                                <p>Chuỗi câu trả lời đúng liên tiếp dài nhất: <strong>${getMaxCorrectSeries(res.round2PointString)}</strong></p>
                                <p>Dữ liệu chi tiết bài thi của thí sinh: <strong>${drawAnswerTable(res.round2PointString)}</strong></p>
                            `;
                        }

                        resultHtml += `
                            <p>BTC xin chúc mừng em đã đạt <strong>Huy Chương Đồng</strong> cuộc thi Mathlish Talent 2024.</p>
                            <p>Chúng tôi hy vọng em sẽ tiếp tục giữ lửa say mê học tập và đạt những thành tích cao hơn nữa tại cuộc thi Mathlish Talent ở những năm học tiếp theo.</p>
                            <p>Xin trân trọng cảm ơn em cùng gia đình!</p>
                        `;
                        break;
                }

            }
            
            resultHtml += `<div id="share-result"><a href="${`https://www.facebook.com/dialog/share?app_id=532691489127140&href=${encodeURIComponent(`https://mathlishtalent.vn/result/2024/${ studentId }`)}&display=popup`}" target="_blank" rel="noreferrer">Chia sẻ kết quả thi</a></div>`;
            resultHtml += `</div>`;


            setSearchResult(resultHtml);

        } catch (error) {
            console.error('Error fetching the result:', error);
        }
    };

    useEffect(() => {
        handleScroll("contest-result");

        if (Year == "2024") {
            (ID)
                ? handleSearch()
                : navigate("/result");
        }
    }, [Year, ID]);

    function getMaxCorrectSeries(pointString: string): number {
        const pointsArray = pointString.split('');
        let maxLength: number = 0;
        let currentLength: number = 0;

        pointsArray.forEach(point => {
            if (parseInt(point) > 0) {
                currentLength++;
                maxLength = Math.max(maxLength, currentLength);
            } else {
                currentLength = 0;
            }
        });

        return maxLength;
    };

    function drawAnswerTable(pointString: string): string {
        let table: string = ``;
        if (pointString) {
            table += `<table class="answers-table">`;

            for (let i = 0; i < 5; i++) {
                table += `<tr>`;
                for (let j = 0; j < 5; j++) {
                    const questionNumber = (i * 5) + j + 1;
                    const point = pointString.charAt(questionNumber - 1);

                    if (parseInt(point) > 0) {
                        table += `<td class="correct">${questionNumber}</td>`;
                    } else {
                        table += `<td class="wrong">${questionNumber}</td>`;
                    }
                }
                table += `</tr>`;
            }

            table += `</table>`;
        }

        return table;
    };

    return (
        <>
            <Helmet>
                {ID ? (
                    <title>Kết quả thi MTEC 2024: Số báo danh {ID}</title>
                ) : (
                    <title>Tra cứu kết quả thi Mathlish Talent Exploring Contest</title>
                )}

                {ID ? (
                    <meta name="description" content={`Kết quả thi MTEC 2024: Số báo danh ${ID}`} />
                ) : (
                    <meta name="description" content="Tra cứu kết quả thi MTEC 2024" />
                )}

                <meta name="robots" content="index, follow" />
                
                {ID ? (
                    <link rel="canonical" href={window.location.origin +`/result/${Year}/${ID}`} />
                ) : (
                    <link rel="canonical" href={window.location.origin +`/result`} />
                )}

                {ID ? (
                    <meta property="og:title" content={`Kết quả thi MTEC 2024: Số báo danh ${ID}`} />
                ) : (
                    <meta property="og:title" content="Tra cứu kết quả thi Mathlish Talent Exploring Contest MTEC 2024" />
                )}

                {ID ? (
                    <meta property="og:url" content={window.location.origin +`/result/${Year}/${ID}`} />
                ) : (
                    <meta property="og:url" content={window.location.origin +`/result`} />
                )}

                <meta property="og:type" content="website" />
                <meta property="og:image" content={window.location.origin +`/img/thumbnail.png`} />
                
                <meta property="og:description" content="Hãy tham gia Mathlish Talent Exploring Contest 2024 - Cuộc thi tìm kiếm tài năng Toán tiếng Anh với nhiều giải thưởng hấp dẫn dành cho học sinh cấp tiểu học trên toàn quốc!" />
                <meta property="og:site_name" content="Mathlish Talent Exploring Contest 2024" />
                <meta property="og:locale" content="vi_VN" />
            </Helmet>

            <Menu />

            <HeadBanner />

            <section id="contest-result">
                <div className="container content">

                    {ID ? (
                        <>
                            <h2>KẾT QUẢ THI MTEC 2024: SỐ BÁO DANH {ID}</h2>
                        </>

                    ) : (
                        <>
                            <h2>TRA CỨU KẾT QUẢ THI MTEC 2024</h2>

                            <p>Vui lòng điền số báo danh của thí sinh dưới đây.</p>
                            <p>Sau đó ấn vào hình kính lúp hoặc gõ phím Enter để tra cứu kết quả thi của thí sinh.</p>

                            <Box>
                                <Paper
                                    component="form"
                                    sx={{ display: 'flex', alignItems: 'center', width: "100%" }} >

                                    <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Tìm kiếm số báo danh..."
                                        inputProps={{ 'aria-label': 'Tìm kiếm số báo danh...' }}
                                        value={studentId}
                                        onChange={(e) => setStudentId(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                    />

                                    <IconButton type="button" sx={{ p: '6px' }} aria-label="search" onClick={handleSearch}>
                                        <Search />
                                    </IconButton>
                                </Paper>
                            </Box>
                        </>
                    )}
                </div>

                <div className="container result">
                    <div dangerouslySetInnerHTML={{ __html: searchResult }} />
                </div>

            </section>

            <Footer />

            <Banner />

        </>
    );
};

export default Page;