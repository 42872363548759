import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import Menu from "../components/menu";
import HeadBanner from "../components/headbanner";
import Footer from "../components/footer";
import Banner from "../components/banner";

import "../styles/home-structure.scss";
import "../styles/home-instructions.scss";
import "../styles/home-gifts.scss";

const Page = (): JSX.Element => {

    const handleScroll = (targetId: string) => {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <title>Mathlish Talent Exploring Contest MTEC 2024: Khám phá tài năng Toán tiếng Anh!</title>

                <meta name="description" content="Hãy tham gia Mathlish Talent Exploring Contest 2024 - Cuộc thi tìm kiếm tài năng Toán tiếng Anh với nhiều giải thưởng hấp dẫn dành cho học sinh cấp tiểu học trên toàn quốc!" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={window.location.origin +"/"} />

                <meta property="og:title" content="Mathlish Talent Exploring Contest MTEC 2024" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location.origin} />
                <meta property="og:image" content={window.location.origin +"/img/thumbnail.png"} />
                <meta property="og:description" content="Hãy tham gia Mathlish Talent Exploring Contest 2024 - Cuộc thi tìm kiếm tài năng Toán tiếng Anh với nhiều giải thưởng hấp dẫn dành cho học sinh cấp tiểu học trên toàn quốc!" />
                <meta property="og:site_name" content="Mathlish Talent Exploring Contest" />
                <meta property="og:locale" content="vi_VN" />
            </Helmet>

            <Menu />

            <HeadBanner />

            <section id="contest-structure">
                <div className="container general-info">
                    <div className="headline">
                        <img src="/img/general-info-text.png" />
                    </div>

                    <div className="content">
                        <p>Nhằm góp phần đẩy mạnh phong trào học Toán Tiếng Anh trên toàn quốc, Trung tâm Toán Tiếng Anh Mathlish x Kiến Guru tổ chức Kỳ thi MATHLISH TALENT - Tìm kiếm Tài năng Toán Tiếng Anh năm 2024. Thông tin cụ thể như sau:</p>

                        <h3 style={{ color: "red" }}>1. Hình thức tổ chức:</h3>
                        <p>Kì thi tổ chức online chia làm 2 vòng</p>

                        <h3 style={{ color: "red" }}>2. Mục đích kỳ thi:</h3>
                        <p style={{ fontWeight: "bold" }}>Khám Phá Và Phát Triển Tài Năng:</p>
                        <p>Tìm kiếm và phát hiện những học sinh có năng khiếu và đam mê môn Toán Tiếng Anh.</p>
                        <p>Bồi dưỡng và đào tạo các tài năng trẻ, giúp các em phát triển vượt bậc trong lĩnh vực toán học.</p>

                        <p style={{ fontWeight: "bold" }}>Khuyến Khích Học Tập:</p>
                        <p>Tạo động lực học tập và rèn luyện cho học sinh thông qua các thử thách và giải thưởng hấp dẫn.</p>
                        <p>Khơi dậy sự hứng thú và niềm yêu thích với môn Toán Tiếng Anh ở các em học sinh từ lớp 2 đến lớp 5.</p>

                        <p style={{ fontWeight: "bold" }}>Xây Dựng Sân Chơi Học Thuật:</p>
                        <p>Tạo ra một sân chơi học thuật lý thú và bổ ích, nơi học sinh có thể giao lưu, học hỏi và phát triển.</p>
                        <p>Khẳng định uy tín và chất lượng giáo dục của Mathlish x Kiến Guru trong lĩnh vực Toán Tiếng Anh trực tuyến.</p>

                        <p style={{ fontWeight: "bold" }}>Thúc Đẩy Sự Phát Triển Toàn Diện:</p>
                        <p>Giúp các em phát triển toàn diện cả về tư duy logic, khả năng ngôn ngữ và kỹ năng giao tiếp.</p>
                        <p>Định hướng và hỗ trợ các em trong hành trình học tập và phát triển bản thân, chuẩn bị cho tương lai.</p>

                        <h3 style={{ color: "red" }}>3. Đối tượng tham gia:</h3>
                        <p>Học sinh lớp 2 đến lớp 5 (tính theo năm học 2024-2025) trên toàn quốc</p>

                        <p style={{ fontWeight: "bold" }}>Lệ phí thi:</p>
                        <p>
                            Lệ phí dự thi: 150K/học sinh<br />
                            Tuy nhiên chi phí này sẽ được trung tâm tài trợ 100% trong năm đầu tiên.
                        </p>



                    </div>

                    <div className="seemore" onClick={() => handleScroll("contest-instructions")}>
                        <img src="/img/more-text.png" />
                    </div>
                </div>
                <div className="container structure">
                    <div className="headline">
                        <img src="/img/structure-text.png" />
                    </div>

                    <div className="content">
                        <p style={{ fontWeight: "bold" }}>Bài thi gồm 3 phần, tổng cộng 25 câu:</p>
                        <p>- Phần A: 15 câu trắc nghiệm, làm đúng +2 điểm, sai không bị trừ điểm</p>
                        <p>- Phần B: 5 câu điền đáp án, làm đúng +6 điểm, sai không bị trừ điểm</p>
                        <p>- Phần C: 5 câu điền đáp án, làm đúng +8 điểm, sai không bị trừ điểm</p>

                        <p style={{ fontWeight: "bold" }}>Ngôn ngữ sử dụng trong đề thi</p>
                        <p>- Vòng 1: Đề tiếng Anh, có ghi chú thuật ngữ quan trọng</p>
                        <p>- Vòng 2: Đề tiếng Anh, không có ghi chú thuật ngữ</p>
                        <p>Thời gian làm bài: 90 phút</p>
                        <p>Điểm tối đa 100 điểm</p>

                        <p style={{ fontWeight: "bold" }}>Lưu ý: Để vào vòng 2 và tham gia lớp Tài Năng, học sinh sẽ cần có điểm thi lớn hơn hoặc bằng điểm sàn (Điểm sàn sẽ được công bố sau khi có kết quả thi)</p>
                    </div>

                    <div className="seemore" onClick={() => handleScroll("contest-gifts")}>
                        <img src="/img/more-text.png" />
                    </div>

                </div>

            </section>

            <section id="contest-gifts">
                <div className="headline">
                    <img src="/img/gift-text.png" />
                </div>

                <div className="container winner">
                    <div className="gift-headline">
                        <h3>4 Giải Nhất</h3>
                    </div>

                    <div className="flex">
                        <div className="gift-detail">
                            <p>Được trao cho thí sinh có số điểm cao nhất mỗi khối với các phần quà cụ thể như sau</p>
                            <p>+ 1 Máy tính bảng Samsung Galaxy Tab A9</p>
                            <p>+ 1 Balo rút Mathlish x Kiến Guru</p>
                            <p>+ 1 Sách OLYMPIAD MATHS TRAINER</p>
                            <p>+ Huy chương Giải Nhất và Giấy chứng nhận</p>
                            <p>+ 1 quyển từ điển Toán Tiếng Anh Mathlish</p>
                            <p>+ 1 vở viết Mathlish - Kiến Guru</p>

                        </div>

                        <div className="gift-images">
                            <img className="tablet" src="/img/gift-tablet-1.png" />
                            <img className="bag-1" src="/img/gift-bag-1.png" />
                            <img className="book-2" src="/img/gift-book-2.png" />
                            <img className="medals" src="/img/gift-medals.png" />
                            <img className="book-1" src="/img/gift-book-1.png" />
                            <img className="book-3" src="/img/gift-book-3.png" />
                            <img className="book-4" src="/img/gift-book-4.png" />
                            <img className="shelf" src="/img/gift-shelf.png" />
                        </div>

                    </div>
                </div>

                <div className="container runner-up">
                    <div className="gift-headline">
                        <h3>4 Giải Nhì</h3>
                    </div>

                    <div className="flex">
                        <div className="gift-detail">
                            <p>Được trao cho thí sinh có số điểm cao thứ 2 mỗi khối với các phần quà cụ thể như sau</p>
                            <p>+ 1 Đồng hồ thông minh Xiaomi Mi Band 8</p>
                            <p>+ 1 Tai nghe Bluetooth chụp tai Havit H633BT</p>
                            <p>+ 1 Balo rút Mathlish x Kiến Guru</p>
                            <p>+ 1 Sách OLYMPIAD MATHS TRAINER</p>
                            <p>+ Huy chương Giải Nhì và Giấy chứng nhận</p>
                            <p>+ 1 quyển từ điển Toán Tiếng Anh Mathlish</p>
                            <p>+ 1 vở viết Mathlish - Kiến Guru</p>

                        </div>

                        <div className="gift-images">
                            <img className="headphone-1" src="/img/gift-headphone-1.png" />
                            <img className="wristband-1" src="/img/gift-wristband-1.png" />
                            <img className="bag-1" src="/img/gift-bag-1.png" />
                            <img className="book-2" src="/img/gift-book-2.png" />
                            <img className="medals" src="/img/gift-medals.png" />
                            <img className="book-1" src="/img/gift-book-1.png" />
                            <img className="book-3" src="/img/gift-book-3.png" />
                            <img className="book-4" src="/img/gift-book-4.png" />
                            <img className="shelf" src="/img/gift-shelf.png" />
                        </div>

                    </div>
                </div>

                <div className="container third-place">
                    <div className="gift-headline">
                        <h3>4 Giải Ba</h3>
                    </div>

                    <div className="flex">
                        <div className="gift-detail">
                            <p>Được trao cho thí sinh có số điểm cao thứ 3 mỗi khối với phần quà cụ thể như sau</p>
                            <p>+ Tai nghe Bluetooth chụp tai Baseus Bowie H1i</p>
                            <p>+ 1 Balo rút Mathlish x Kiến Guru</p>
                            <p>+ 1 Sách OLYMPIAD MATHS TRAINER</p>
                            <p>+ Huy chương Giải Ba và Giấy chứng nhận</p>
                            <p>+ 1 quyển từ điển Toán Tiếng Anh Mathlish</p>
                            <p>+ 1 vở viết Mathlish - Kiến Guru</p>
                        </div>

                        <div className="gift-images">
                            <img className="headphone-2" src="/img/gift-headphone-2.png" />
                            <img className="bag-1" src="/img/gift-bag-1.png" />
                            <img className="book-2" src="/img/gift-book-2.png" />
                            <img className="medals" src="/img/gift-medals.png" />
                            <img className="book-1" src="/img/gift-book-1.png" />
                            <img className="book-3" src="/img/gift-book-3.png" />
                            <img className="book-4" src="/img/gift-book-4.png" />
                            <img className="shelf" src="/img/gift-shelf.png" />

                        </div>

                    </div>
                </div>

                <div className="container fourth-place">
                    <div className="gift-headline">
                        <h3>88 Giải Khuyến khích</h3>
                    </div>
                    <div className="flex">
                        <div className="gift-detail">
                            <p>Được trao cho 88 thí sinh còn lại với phần quà cụ thể như sau:</p>
                            <p>+ 1 quyển từ điển Toán Tiếng Anh Mathlish</p>
                            <p>+ 1 vở viết Mathlish - Kiến Guru</p>
                            <p>+ 1 Giấy chứng nhận giải khuyến khích</p>
                        </div>

                        <div className="gift-images">
                            <img className="book-1" src="/img/gift-book-1.png" />
                            <img className="book-3" src="/img/gift-book-3.png" />
                            <img className="book-4" src="/img/gift-book-4.png" />
                            <img className="shelf" src="/img/gift-shelf.png" />
                        </div>

                    </div>
                </div>

                <div className="container certificate">
                    <div className="gift-detail">
                        <p style={{ fontWeight: "bold" }}>Demo giấy chứng nhận kì thi Mathlish Talent</p>
                    </div>
                    <div className="gift-images">
                        <img src="/img/gift-cert-1.png" />
                    </div>
                </div>

            </section>

            <section id="contest-instructions">
                <div className="container instructions">
                    <div className="headline">
                        <img src="/img/instruction-text.png" />
                    </div>

                    <div className="content">
                        <p style={{ fontWeight: "bold", textAlign: "center" }}>Để tham gia dự thi phụ huynh giúp các con thực hiện theo các bước và timeline như sau</p>
                        <img src="/img/instruction-table.png" />
                    </div>
                </div>

                <div className="container timeline">
                    <div className="headline">
                        <img src="/img/time-text.png" />
                    </div>

                    <div className="content">

                        <div className="contest-date">
                            <div className="contest-date-panel">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td rowSpan={2}>
                                                <span>NGÀY THI:</span>
                                            </td>
                                            <td>
                                                <span>Vòng 1:</span> <span>14/7/2024</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span>Vòng 2:</span> <span>28/7/2024</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <span>KHỐI:</span> <span>Các khối lớp 2-3-4-5</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>

                        <div className="contest-time">
                            <div className="contest-time-panel">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span>8h40</span>
                                            </td>
                                            <td>
                                                <span>Có mặt tại phòng thi</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span>8h45</span>
                                            </td>
                                            <td>
                                                <span>Phổ biến quy chế thi</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span>9h00</span>
                                            </td>
                                            <td>
                                                <span>Bắt đầu làm bài</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span>10h30</span>
                                            </td>
                                            <td>
                                                <span>Kết thúc làm bài</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>

            </section>

            <Footer />

            <Banner />
            
        </>
    );
};

export default Page;