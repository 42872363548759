import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";

import { InputBase, IconButton, Box, Paper } from "@mui/material";

import Data from "../data/2024.json";

import Menu from "../components/menu";
import HeadBanner from "../components/headbanner";
import Footer from "../components/footer";
import Banner from "../components/banner";

import "../styles/menu.scss";
import "../styles/home-intro.scss";
import "../styles/honor-board.scss";
import "../styles/result.scss";
import "../styles/footer.scss";


const Page = (): JSX.Element => {
    const { Year, Alias } = useParams();
    console.log("Year:", Year, "Alias:", Alias);

    const navigate = useNavigate();
    const [studentId, setStudentId] = useState("");

    const handleScroll = (targetId: string) => {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        if (!Year) {
            navigate(`/honor-board/2024`);
        }
        
        if (Alias) {            
            
        }
    }, [Year, Alias]);

    useEffect(() => {
        handleScroll("contest-result");
    }, []);

    console.log(Data);

    return (
        <>
            <Helmet>
                <title>Bảng vinh danh thí sinh đạt thành tích cao trong cuộc thi Mathlish Talent</title>

                <meta name="description" content="Trang vinh danh các thí sinh đạt thành tích cao trong cuộc thi Mathlish Talent Exploring Contest (MTEC)" />
                <meta name="robots" content="noindex, nofollow" />

                <meta property="og:title" content="Bảng vinh danh thí sinh đạt thành tích cao trong cuộc thi Mathlish Talent" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="/honor-board" />
                <meta property="og:image" content="img/thumbnail.png" />
                <meta property="og:description" content="Trang vinh danh các thí sinh đạt thành tích cao trong cuộc thi Mathlish Talent Exploring Contest (MTEC)" />
                <meta property="og:site_name" content="Mathlish Talent Exploring Contest" />
                <meta property="og:locale" content="vi_VN" />
            </Helmet>

            <Menu />

            <HeadBanner />

            <section id="contest-honorboard">
                <div className="container content">

                    <h2>BẢNG VINH DANH THÍ SINH DÀNH HUY CHƯƠNG MTEC 2024</h2>

                </div>

                <div className="container result">
                    <h3 className="grade-title">Khối lớp 2 :</h3>
                    <div className="result-row">
                    {
                        Data.map((item, index) => {
                            if (item.grade == 2) {
                                return (
                                    <div key={index} className="result-item">
                                        <div className="medal">{item.medal}</div>
                                        <div className="info">
                                            <div className="left">
                                                <div className="student-image">
                                                    <img src={item.image} />
                                                </div>

                                            </div>
                                            <div className="right">
                                                <div className="name"><strong>{item.name}</strong></div>
                                                <div className="id">SBD: {item.id}</div>
                                                <div className="dob">Sinh ngày: {item.dob}</div>
                                                <div className="school">Trường: {item.school}</div>
                                                <div className="city">Tỉnh thành: {item.city}</div>
                                                <div className="score">Điểm số: {item.finalScore}</div>
                                                <div className="duration">Thời gian làm bài: {item.duration}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }   
                        })
                    }
                    </div>
                    
                    <br />
                    <h3 className="grade-title">Khối lớp 3 :</h3>
                    <div className="result-row">
                    {
                        Data.map((item, index) => {
                            if (item.grade == 3) {
                                return (
                                    <div key={index} className="result-item">
                                        <div className="medal">{item.medal}</div>
                                        <div className="info">
                                            <div className="left">
                                                <div className="student-image">
                                                    <img src={item.image} />
                                                </div>

                                            </div>
                                            <div className="right">
                                                <div className="name"><strong>{item.name}</strong></div>
                                                <div className="id">SBD: {item.id}</div>
                                                <div className="dob">Sinh ngày: {item.dob}</div>
                                                <div className="school">Trường: {item.school}</div>
                                                <div className="city">Tỉnh thành: {item.city}</div>
                                                <div className="score">Điểm số: {item.finalScore}</div>
                                                <div className="duration">Thời gian làm bài: {item.duration}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }   
                        })
                    }
                    </div>
                    
                    <br />
                    <h3 className="grade-title">Khối lớp 4 :</h3>
                    <div className="result-row">
                    {
                        Data.map((item, index) => {
                            if (item.grade == 4) {
                                return (
                                    <div key={index} className="result-item">
                                        <div className="medal">{item.medal}</div>
                                        <div className="info">
                                            <div className="left">
                                                <div className="student-image">
                                                    <img src={item.image} />
                                                </div>

                                            </div>
                                            <div className="right">
                                                <div className="name"><strong>{item.name}</strong></div>
                                                <div className="id">SBD: {item.id}</div>
                                                <div className="dob">Sinh ngày: {item.dob}</div>
                                                <div className="school">Trường: {item.school}</div>
                                                <div className="city">Tỉnh thành: {item.city}</div>
                                                <div className="score">Điểm số: {item.finalScore}</div>
                                                <div className="duration">Thời gian làm bài: {item.duration}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }   
                        })
                    }
                    </div>
                    
                    <br />
                    <h3 className="grade-title">Khối lớp 5 :</h3>
                    <div className="result-row">
                    {
                        Data.map((item, index) => {
                            if (item.grade == 5) {
                                return (
                                    <div key={index} className="result-item">
                                        <div className="medal">{item.medal}</div>
                                        <div className="info">
                                            <div className="left">
                                                <div className="student-image">
                                                    <img src={item.image} />
                                                </div>

                                            </div>
                                            <div className="right">
                                                <div className="name"><strong>{item.name}</strong></div>
                                                <div className="id">SBD: {item.id}</div>
                                                <div className="dob">Sinh ngày: {item.dob}</div>
                                                <div className="school">Trường: {item.school}</div>
                                                <div className="city">Tỉnh thành: {item.city}</div>
                                                <div className="score">Điểm số: {item.finalScore}</div>
                                                <div className="duration">Thời gian làm bài: {item.duration}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }   
                        })
                    }
                    </div>
                    
                    
                    
                </div>


            </section>

            <Footer />

            <Banner />

        </>
    );
};

export default Page;