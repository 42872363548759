import React from "react";

import "../styles/home-intro.scss";

const MenuButtons = () => {
    const handleScroll = (targetId: string) => {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <section id="contest-intro">

            <div className="floating-icons">
                <img id="el-1" src="/img/el-1.png" />
                <img id="el-2" src="/img/el-2.png" />
                <img id="el-3" src="/img/el-3.png" />
                <img id="el-4" src="/img/el-1.png" />
            </div>

            <div className="contest-logo">
                <img src="/img/logo-white.png" />
            </div>

            <div className="contest-name">
                <h1>Kỳ thi tìm kiếm tài năng toán tiếng anh</h1>
                <img src="/img/mathlish-talent-text.png" />
            </div>

            <div className="contest-intro-text">
                <div className="contest-intro-text-wrapper">
                    <p>Mathlish x Kiến Guru là trung tâm Toán Tiếng Anh trực tuyến hàng đầu đã hoạt động trong suốt 10 năm qua. Chúng tôi luôn nỗ lực cung cấp cơ hội học tập tiên tiến, giúp hàng ngàn học sinh Việt Nam tiếp cận chương trình Toán Tiếng Anh chuẩn quốc tế, trở thành địa chỉ học tập đáng tin cậy khắp cả nước.</p>

                    <p>Với mong muốn tạo ra sân chơi bổ ích và tìm kiếm những học sinh thực sự tài năng, yêu thích bộ môn Toán Tiếng Anh, Mathlish x Kiến Guru tổ chức kỳ thi "TÌM KIẾM TÀI NĂNG TOÁN HỌC - MATHLISH TALENT 2024". </p>

                    <p>Chúng tôi hy vọng MATHLISH TALENT sẽ sớm trở thành sự kiện thường niên uy tín, thúc đẩy tinh thần học tập và phát triển cho các em học sinh.</p>
                </div>

                <div className="contest-kid">
                    <img src="/img/kid.png" />
                </div>

                <div className="seemore" onClick={() => handleScroll("contest-structure")}>
                    <img src="/img/more-text.png" />
                </div>
            </div>


        </section>
    );
};

export default MenuButtons;