import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import * as echarts from "echarts";

import {
    Box, Paper, Grid,
    InputBase, IconButton, 
    Select, MenuItem
} from "@mui/material";

import Menu from "../components/menu";
import HeadBanner from "../components/headbanner";
import Footer from "../components/footer";
import Banner from "../components/banner";

import "../styles/result.scss";


const Page = (): JSX.Element => {
    const [contestYear, setContestYear] = useState(2024);
    const [contestRound, setContestRound] = useState(1);
    const [grade, setGrade] = useState(2);

    const chartOfScoreSpectrumRef = useRef(null);
    const chartOfMaxCorrectSerieSizeSpectrumRef = useRef(null);
    const chartOfCorrectAnswersStatsRef = useRef(null);
    const [data, setData] = useState<{
        grade: number;
        dataSize: number;
        scoreSpectrum: { range: string, count: number }[];
        maxCorrectSerieSizeSpectrum: { range: string, count: number }[];
        correctAnswersStats: { correct: number, correctPercentage: number }[];
    }>();

    const [mostWrongQuestions, setMostWrongQuestions] = useState<number[]>([]);

    const handleScroll = (targetId: string) => {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        handleScroll("contest-result");
    }, []);

    useEffect(() => {
        // v1/statistic/2024/firstround?grade=2
        let round = contestRound === 1 ? "firstround" : "finalround";
        let URL = `https://api.mathlishtalent.vn/v1/statistic/${contestYear}/${round}?grade=${grade}`;

        axios.get(URL)
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                console.error("Error fetching data", error);
            });
    }, [contestYear, contestRound, grade]);

    useEffect(() => {

        // Update 10 most wrong questions
        if (data?.correctAnswersStats) {
            let lowest: number[] = getLowestCorrectPercentageIndexes(data?.correctAnswersStats, 10);
            setMostWrongQuestions( lowest );
        }


        // Charts
        // Score Spectrum
        const myChartOfScoreSpectrum = echarts.init(chartOfScoreSpectrumRef.current);
        const option = {
            tooltip: {
                trigger: 'axis',
            },
            xAxis: {
                type: 'category',
                data: data?.scoreSpectrum.map(item => item.range),
                axisPointer: {
                    type: 'shadow'
                }
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    data: data?.scoreSpectrum.map(item => item.count *2),
                    type: 'bar'
                }
            ]
        };
        myChartOfScoreSpectrum.setOption(option);

        // Max Correct Serie Size Spectrum
        const myChartOfMaxCorrectSerieSizeSpectrum = echarts.init(chartOfMaxCorrectSerieSizeSpectrumRef.current);
        const option2 = {
            tooltip: {
                trigger: 'axis',
            },
            xAxis: {
                type: 'category',
                data: data?.maxCorrectSerieSizeSpectrum.map(item => item.range),
                axisPointer: {
                    type: 'shadow'
                }
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    data: data?.scoreSpectrum.map(item => item.count),
                    type: 'bar'
                }
            ]
        };
        myChartOfMaxCorrectSerieSizeSpectrum.setOption(option2);

        // Correct Answers Stats
        const myChartOfCorrectAnswersStats = echarts.init(chartOfCorrectAnswersStatsRef.current);
        const option3 = {
            xAxis: {
                type: 'category',
                data: ["1", "2", "3", "4", "5"],
                splitArea: {
                    show: true
                }
            },
            yAxis: {
                type: 'category',
                data: ["21-25", "16-20", "11-15", "6-10", "1-5"],
                splitArea: {
                    show: true
                }
            },
            visualMap: {
                min: 0,
                max: 100,
                calculable: true,
                orient: 'horizontal',
                left: 'center',
                bottom: '0'
            },
            series: [
                {
                    name: 'Card',
                    type: 'heatmap',
                    data: parseHeatmapData(data?.correctAnswersStats),
                    label: {
                        show: true
                    },
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]

        };
        myChartOfCorrectAnswersStats.setOption(option3);

    }, [data]);

    function parseHeatmapData(heatmapRawData: { correct: number, correctPercentage: number }[] | undefined): number[][] {
        const heatmapData = [];
        const rows = 5; // Number of rows
        const columns = 5; // Number of columns
        
        if (!heatmapRawData) return [[]];
        
        for (let i = 0; i < heatmapRawData.length; i++) {
            // Calculate row index based on the question number
            const row = Math.floor(i / columns);
            
            // Calculate column index within the row
            const column = i % columns;
            
            // Convert row index for heatmap (top row is 0)
            const y = rows - 1 - row;
            
            // Push the position and value
            heatmapData.push([column, y, heatmapRawData[i].correctPercentage]);
        }
        
        return heatmapData;
    };

    function getLowestCorrectPercentageIndexes (data: { correct: number; correctPercentage: number }[], count: number): number[] {
        return data
            .map((item, index) => ({ index, correctPercentage: item.correctPercentage }))
            .sort((a, b) => a.correctPercentage - b.correctPercentage)
            .slice(0, count)
            .map(item => item.index + 1);
    };

    return (
        <>
            <Helmet>
                <title>Thống kê kết quả thi Mathlish Talent Exploring Contest</title>

                <meta name="description" content="Thống kê kết quả thi MTEC 2024" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={window.location.origin +"/statistic"} />

                <meta property="og:title" content="Thống kê kết quả thi Mathlish Talent Exploring Contest MTEC 2024" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location.origin +"/statistic"} />
                <meta property="og:image" content={window.location.origin +"/img/thumbnail.png"} />
                <meta property="og:description" content="Hãy tham gia Mathlish Talent Exploring Contest 2024 - Cuộc thi tìm kiếm tài năng Toán tiếng Anh với nhiều giải thưởng hấp dẫn dành cho học sinh cấp tiểu học trên toàn quốc!" />
                <meta property="og:site_name" content="Mathlish Talent Exploring Contest" />
                <meta property="og:locale" content="vi_VN" />
            </Helmet>

            <Menu />

            <HeadBanner />

            <section id="contest-result">
                <div className="container content">

                    <h2>THỐNG KÊ DỮ LIỆU CUỘC THI TOÁN TIẾNG ANH MTEC 2024</h2>

                    <Select
                        value={grade}
                        onChange={(e) => setGrade(Number(e.target.value))}
                        label="Khối"
                        variant="outlined"
                    >
                        <MenuItem value={2}>Lớp 2</MenuItem>
                        <MenuItem value={3}>Lớp 3</MenuItem>
                        <MenuItem value={4}>Lớp 4</MenuItem>
                        <MenuItem value={5}>Lớp 5</MenuItem>
                    </Select>
                </div>

                <div className="container result">
                    {data && (
                        <p><strong>Phổ điểm khối { data.grade }:</strong></p>
                    )}

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div ref={chartOfScoreSpectrumRef} style={{ width: '100%', height: '250px' }}></div>
                        </Grid>
                    </Grid>
                    <br />

                    {data && (
                        <p><strong>Phổ chuỗi câu trả lời đúng dài nhất khối { data.grade }:</strong></p>
                    )}

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div ref={chartOfMaxCorrectSerieSizeSpectrumRef} style={{ width: '100%', height: '250px' }}></div>
                        </Grid>
                    </Grid>
                    <br />

                    {data && (
                        <>
                            <p><strong>Tỉ lệ % làm đúng theo câu hỏi khối { data.grade }:</strong></p>
                            <p><strong>10 câu hỏi có tỉ lệ sai cao nhất: {mostWrongQuestions.join(", ")}</strong></p>
                        </>
                    )}
                    
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div ref={chartOfCorrectAnswersStatsRef} style={{ width: '100%', height: '350px' }}></div>
                        </Grid>
                    </Grid>
                    <br />

                    <p style={{textAlign: "center"}}>*Kéo thanh tùy chỉnh để lọc câu hỏi theo tỉ lệ</p>

                </div>

            </section>

            <Footer />

            <Banner />

        </>
    );
};

export default Page;