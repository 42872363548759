import React, { useState, useEffect } from "react";

import "../styles/banner.scss";

const Banner = () => {
    const TARGET_URL = "https://www.facebook.com/hoctoantienganh.mathlish";
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowPopup(true);
        }, 10000);

        return () => clearTimeout(timer); // Cleanup the timer
    }, []); // Empty dependency array means this effect runs once on mount


    const handleCloseClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation(); // Prevent click from bubbling to #ads-mlo-popup
        setShowPopup(false); // Hide the popup
    };

    const handlePopupClick = () => {
        window.location.href = TARGET_URL; // Redirect to desired URL
    };

    if (!showPopup) {
        return null;
    }

    return (
        <div id="ads-mlo-popup">
            <div id="ads-mlo-banner" style={{ display: "table" }} onClick={handlePopupClick}>
                <div id="ads-mlo-content-wrap" style={{ display: "table-row" }}>
                    <div id="ads-mlo-tranbinh-img" className="tablecell">
                        <img src="/img/kid2.png" />
                    </div>

                    <div id="ads-mlo-content" className="tablecell">
                        <div className="ads-mlo-content-col ads-content-text">
                            <p>Trải nghiệm lớp học <br />Toán tiếng Anh online <br />cùng Mathlish và Kiến Guru</p>
                            <a href={ TARGET_URL }>Tìm Hiểu Ngay</a>
                        </div>
                    </div>

                    <div id="ads-close" onClick={handleCloseClick}>Ẩn</div>

                </div>
            </div>
        </div>
    );
};

export default Banner;